.chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*background: initial;*/
    /*padding-top: 50px;*/
    /*color: #ff0000;*/
}

.chart_container {
    display: flex;
}

.btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.header_chart {
    margin-bottom: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr .5fr 1fr;
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    gap: 8px;
}

.zooming {
    display: flex;
    align-items: center;
    gap: 8px;
}

.zoom_action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid rgba(48, 48, 48, 1);
    background: rgba(31, 31, 31, 1);
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
}

.btn_setting {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(48, 48, 48, 1);
    background: rgba(31, 31, 31, 1);
    border-radius: 5px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    overflow: hidden;
    cursor: pointer;
    transition: .3s all;
}

.chart_wrapper {
    border: 1px solid rgba(48, 48, 48, 1);
    background: rgba(31, 31, 31, 1);
    width: 100%;
    margin-left: 8px;
    border-radius: 10px;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    height: fit-content;

}

.btn_setting_active {
    border: 1px solid #6FCF97 !important;
    background: rgba(41, 49, 44, 1) !important;
}

.btn_setting_active svg {
    fill: #6FCF97 !important;
}

.dataRangeBox {
    /*border: 1px solid red;*/
}

.wrapper_date {
    max-width: 100%;
    width: 100%;
}

.all_settings {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 0 12px;
    /*justify-content: space-between;*/
    /*height: 31px;*/
    background: rgba(31, 31, 31, 1);
    border: 1px solid rgba(48, 48, 48, 1);
    border-radius: 5px;
    cursor: pointer;;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: rgba(189, 189, 189, 1);
}

.date_input {
    width: 100%;
    height: 100%;
    /*height: 31px;*/
    display: flex;
    align-items: center;
    padding: 0 12px;
    /*justify-content: space-between;*/
    background: rgba(31, 31, 31, 1);
    border: 1px solid rgba(48, 48, 48, 1);
    border-radius: 5px;
    cursor: pointer;;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: rgba(189, 189, 189, 1);

}

.empty {
    font-family: Montserrat, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.6px;
    color: #cecece;
    text-align: center;
    white-space: nowrap;
}

@media screen and (max-width: 980px) {
    .header_chart {
        margin-bottom: 8px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    .chart_container {
        display: flex;
        flex-direction: column;
    }

    .btns {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
    }

    .btn_setting {
        width: 100%;
        min-width: fit-content;
        max-width: 100%;
    }

    .chart_wrapper {
        border: 1px solid rgba(48, 48, 48, 1);
        background: rgba(31, 31, 31, 1);
        width: 100%;
        margin-left: 0px;
        border-radius: 10px;
        padding-right: 0px;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 400px;
        height: fit-content;
        margin-top: 12px;
    }

    .wrapper_date {
        height: 40px;
        /*background: rgba(41, 49, 44, 1);*/
    }
}
