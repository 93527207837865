.input {
    min-width: 100px;
    max-width: 100px;
    width: fit-content;
    height: 23px;
    padding: 0 14px;
    background: #3C3C3C;
    border-radius: 5px;

    font-family: Montserrat, sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: center;

}

.input[type='number'] {
    -moz-appearance:textfield;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}