.main {
    /*display: flex;*/
    display: flex;
    /*grid-template-columns: 2fr 1fr;*/
    width: 100%;
    gap: 28px;

}

.empty_box {
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    background: linear-gradient(90deg, #1F1F1F 0%, rgba(31, 31, 31, 0) 100%);
    padding: 14px;
    height: fit-content;

    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.6px;
    color: #cecece;
    text-align: center;
    min-width: 340px;
    max-width: 340px;
}

.left_side {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.table_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 14px;

}

.table_navigate {
    display: flex;
    align-items: center;
    gap: 14px;
}

.navigate_item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 14px 28px;
    border: 1px solid #3C3C3C;
    border-radius: 10px;
    cursor: pointer;
    transition: .4s all;
}

.title_item {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
    color: #828282
}

.navigate_item:hover {
    border: 1px solid #626262;
}

.navigate_item:hover .title_item {
    color: #949494
}

.navigate_item_active {
    background: #1F1F1F;
}

.navigate_item_active svg path {
    fill: #FFFFFF;
}

.navigate_item_active .title_item {
    color: #FFFFFF;
}

.navigate_item_active:hover {
    border: 1px solid #3C3C3C;
}

.navigate_item_active:hover .title_item {
    color: #FFFFFF
}

@media screen and (max-width: 980px) {
    .main {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 14px;

    }

    .setting_chart_desc{
        display: none;
    }
}
